import { verifyTotpCode } from 'api/verifyTotpCode'
import { navigate } from 'gatsby'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LOWWER_LENGTH, UPPER_LENGTH } from 'scripts/secretQAValidate'

interface Props {
  token: string
  appClientKey?: string | null
  isSaml: boolean
}

export const Main: FC<Props> = ({ token, appClientKey, isSaml }) => {
  const [otpAttempt, setOtpAttempt] = useState('')
  const { t } = useTranslation()
  const [openSnackbar] = useSnackbar()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'otp_attempt':
        setOtpAttempt(event.target.value)
        break
      default:
        break
    }
  }
  const handleClick = async () => {
    const { status } = await verifyTotpCode({ token, otp_attempt: otpAttempt })
    if (status === 200) {
      if (appClientKey && isSaml) {
        navigate(`/verifyTotpSuccess?app_client_key=${appClientKey}&saml=true`)
      } else if (appClientKey) {
        navigate(`/verifyTotpSuccess?app_client_key=${appClientKey}`)
      } else if (isSaml) {
        navigate('/verifyTotpSuccess?saml=true')
      } else {
        navigate('/verifyTotpSuccess')
      }
      return
    }
    openSnackbar('ログインに失敗しました')
  }

  return (
    <>
      <p className="text-xl">{t('二段階認証')}</p>
      <p className="text-sm pt-3 px-6 mt-4">
        {t('認証アプリに表示される6桁のコードを入力してください。')}
        <br />
      </p>
      <div className="w-full px-6 pt-5">
        <p className="w-full px-4 font-bold text-sm text-left"></p>
        <p className="text-xs pb-2 pt-1">{t('6桁のコード')}</p>
        <input
          className="rounded px-2 w-full h-8 border border-black"
          data-test="otp_attempt"
          type="text"
          name="otp_attempt"
          value={otpAttempt}
          onChange={handleChange}
        />
        <button
          className="mt-8 h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75"
          data-test="submit"
          onClick={handleClick}
        >
          {t('送信する')}
        </button>
      </div>
      <div className="my-5"></div>
    </>
  )
}
