import { GetCheckTotpVerifyTokenResponse } from 'types/ApiResponse'
import { useAxios } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const useCheckTotpVerifyToken = (token: string | null | undefined) => {
  const [{ data, error, loading }] = useAxios<GetCheckTotpVerifyTokenResponse>(
    `${fullUrl('api')}/endusers/totp?token=${token}`,
  )
  return [{ ...data, error, loading }]
}
