import { fullUrl } from 'scripts/fullUrl'
import { VerifyTotpCodeResponse } from 'types/ApiResponse'
import { VerifyTotpCodeRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const verifyTotpCode = async (params: VerifyTotpCodeRequest) => {
  const { data } = await axiosClient.post<VerifyTotpCodeResponse>(
    `${fullUrl('api')}/endusers/verify_totp`,
    params,
  )
  return data
}
